import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import { gql } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/react-hooks';

import ADD_SMALL_ICON from '../../Images/icons/add-small.png';
import REMOVE_ICON from '../../Images/icons/remove.png';

import { ButtonLink } from '../../Components/Form';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import GenericAlert from '../../Components/GenericAlert';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Loader from '../../Components/Loader';
import Form from './Form';
import { EVENT_TITLE } from '../../Constants/AppConstants';

const COALITION_FIELDS = `
  id
  status
  tenantId
  code
  fetchEventCompetition {
    id
    title
  }
  fetchCoalitionsSchools{
    id
    status
    schoolId
    tenantId
    coalitionId
    fetchSchool {
      id
      name
    }
    fetchCoalitionMembers {
      id
      user {
        id
        code
        firstname
        lastname
      }
    }
  }
`;

const FETCH_COALITION_DETAIL = gql`
query fetchCoalitionDetail($id: Int!){
  fetchCoalitionDetail(id: $id ) {
    ${COALITION_FIELDS}
  }
}
`;

const ADD_SCHOOLS_TO_COALITION = gql`
mutation addSchoolsToCoalition($id: ID!, $schoolIds: [Int!]!) {
  addSchoolsToCoalition(input: {
    id: $id,
    schoolIds: $schoolIds,
  }) {
    coalition {
      ${COALITION_FIELDS}
    }
  }
}
`;

const REMOVE_SCHOOL_FROM_COALITION = gql`
mutation removeSchoolFromCoalition($id: ID!, $schoolId: Int!) {
  removeSchoolFromCoalition(input: {
    id: $id,
    schoolId: $schoolId,
  }) {
    coalition {
      ${COALITION_FIELDS}
    }
  }
}
`;

const DELETE_COALITION = gql`
mutation deleteCoalition($id: Int!) {
  deleteCoalition(input: {
    id: $id
  }) {
    message
  }
}
`;

const FETCH_EVENT_HEADER = gql`
  query fetchEventDetail($id: Int!) {
    fetchEventDetail(id: $id) {
      id
      title
    }
  }
`;

const CoalitionEdit = () => {
  const { currentUser } = useCurrentUser(true);
  const { id, coalitionId } = useParams();

  const { data: eventData, loading: eventLoading } = useQuery(FETCH_EVENT_HEADER, {
    variables: {
      id,
    },
  });
  const event = eventData?.fetchEventDetail ?? {};
  useHeaderComponentsMutation({
    title: 'EDIT COALITION',
    backLink: `/event-manage/${id}/coalitions`,
    components: [{ key: EVENT_TITLE, value: event.title }],
  });

  const history = useHistory();
  const extractError = useGraphQLErrorExtractor();
  const [formKey, setFormKey] = useState(1); // this is an ugly hack, don't do this
  const [addingSchools, setAddingSchools] = useState(false);
  const [schoolIds, setSchoolIds] = useState([]);
  const [schoolDeleting, setSchoolDeleting] = useState([]);

  const { data, loading, error: fetchError } = useQuery(
    FETCH_COALITION_DETAIL,
    {
      variables: { id: parseInt(coalitionId, 10) },
    },
  );

  const [error, setError] = useState();
  const [addSchoolsToCoalition, { loading: addLoading }] = useMutation(ADD_SCHOOLS_TO_COALITION);
  const [removeSchoolFromCoalition] = useMutation(REMOVE_SCHOOL_FROM_COALITION);
  const [deleteCoalition] = useMutation(DELETE_COALITION, {
    variables: {
      id: coalitionId,
    },
    onCompleted: () => {
      history.push(`/event-manage/${id}/coalitions`);
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    setError();
    addSchoolsToCoalition({
      variables: {
        id: coalitionId,
        schoolIds,
      },
    }).catch((err) => {
      setError(err);
      setSchoolIds([]);
    });
    setFormKey((k) => k + 1);
  };

  const onDelete = (schoolId) => {
    setError();
    setSchoolDeleting([...schoolDeleting, schoolId]);
    removeSchoolFromCoalition({
      variables: {
        id: coalitionId,
        schoolId,
      },
    })
      .catch(setError)
      .finally(() => {
        setSchoolDeleting(schoolDeleting.filter((id) => id !== schoolId));
      });
  };

  const updateSchools = (schoolId, actionType) => {
    if (actionType === 'add') {
      setSchoolIds(schoolIds.concat(schoolId));
    } else {
      setSchoolIds(schoolIds.filter((Id) => Id !== schoolId));
    }
  };

  if (loading || eventLoading) {
    return <Loader />;
  }
  if (fetchError) {
    return <GenericAlert>{extractError(fetchError)}</GenericAlert>;
  }

  const coalition = data.fetchCoalitionDetail;
  const alreadySelectedSchoolIds = coalition.fetchCoalitionsSchools.map((cs) => cs.fetchSchool.id);

  return (
    <>
      {error && <GenericAlert>{extractError(error)}</GenericAlert>}
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          <h2>Details</h2>
          <p>
            Coalition:
            {' '}
            {coalition.code}
          </p>
          <p>
            Competition:
            {' '}
            {coalition.fetchEventCompetition.title}
          </p>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-12 mx-auto pt-4 px-4">
          <h3>Members</h3>
          <ul style={{ 'list-style-type': 'none' }}>
            {coalition.fetchCoalitionsSchools.map((cs) => {
              const isBeingDeleted = schoolDeleting.includes(cs.fetchSchool.id);
              return (
                <>
                  <div className="row pt-2 border-top">
                    <h4 className="col-11 mx-auto px-4">{cs.fetchSchool.name}</h4>
                    <IconButton className="col-1" onClick={() => onDelete(cs.fetchSchool.id)} disabled={isBeingDeleted}>
                      {isBeingDeleted ? (
                        <Loader />
                      ) : (
                        <img src={REMOVE_ICON} alt="arrow" className="img-fluid" />
                      )}
                    </IconButton>
                  </div>
                  {cs.fetchCoalitionMembers.length === 0
                    ? <p>No students yet</p>
                    : cs.fetchCoalitionMembers.map((cm) => (
                      <li>
                        {cm.user.code}
                        {' '}
                        {cm.user.firstname}
                        {' '}
                        {cm.user.lastname}
                      </li>
                    ))}
                </>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-12 mx-auto px-4">
          {addingSchools ? (
            <Form
              key={formKey}
              onSubmit={onSubmit}
              submitting={addLoading}
              currentUser={currentUser}
              formType="edit"
              updateSchools={updateSchools}
              eventCompetitionId={coalition.fetchEventCompetition.id}
              alreadySelectedSchoolIds={alreadySelectedSchoolIds}
            />
          ) : (
            <ButtonLink
              backgroundcolor="#F4AB37"
              onClick={() => setAddingSchools(true)}
              fontColor="#FFF"
              border="none"
              addNewText="Add School"
              imageAttached={ADD_SMALL_ICON}
              checkPermission
            />
          )}
        </div>
      </div>
      <div className="row pt-2">
        <h4 className="col-11 mx-auto px-4">DELETE COALITION</h4>
        <IconButton className="col-1" onClick={deleteCoalition}>
          <img src={REMOVE_ICON} alt="arrow" className="img-fluid" />
        </IconButton>
      </div>
    </>
  );
};
export default CoalitionEdit;

export const IconButton = styled(Button)`
  background: none !important;
  border: none !important;
  :hover{
    border: ${(props) => props.activeborder || 'none'} !important;
    box-shadow: ${(props) => props.activeshadow || 'none'} !important;
  }
  :active{
    border: ${(props) => props.activeborder || 'none'} !important;
    box-shadow: ${(props) => props.activeshadow || 'none'} !important;
  }
`;
