import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Select,
  Spinner,
  useToast,
} from '@chakra-ui/react';

const FETCH_ELIGIBLE_EVENT_COMPETITIONS = gql`
  query fetchEligibleEventCompetitions($eventId: Int!, $userId: Int!, $eventUserId: Int!) {
    fetchEventsUserDetail(id: $eventUserId) {
      fetchMembers {
        id
        eventCompetitionId
        status
        fetchEventCompetition {
          id
          title
          fetchCompetition {
            categoryId
          }
        }
      }
    }
    fetchEligibleEventCompetitions(eventId: $eventId, userId: $userId) {
      id
      title
      category {
        id
        title
      }
    }
  }
`;

const SET_EVENT_COMPETITIONS = gql`
  mutation setEventCompetition($input: SetEventCompetitionInput!) {
    setEventCompetition(input: $input) {
      members {
        id
        fetchEventCompetition {
          id
          title
          status
        }
      }
    }
  }
`;

const groupCompetitionsByCategory = (eventCompetitions = []) => eventCompetitions.reduce(
  (acc, comp) => {
    const res = { ...acc };
    if (!res[comp.category.id]) {
      res[comp.category.id] = [];
    }
    res[comp.category.id] = [...res[comp.category.id], comp];
    return res;
  },
  {},
);

const categoriesById = (eventCompetitions = []) => eventCompetitions
  .map((eventComp) => eventComp.category)
  .reduce((acc, cat) => ({
    ...acc,
    [cat.id]: cat,
  }), {});

const CompetitionsModalContent = ({
  eventCompetitions,
  selectedCompsByCategory,
  onChangeSelectedComp,
}) => {
  const categories = categoriesById(eventCompetitions);
  const compsByCategory = groupCompetitionsByCategory(eventCompetitions);
  const compsByCategoryInOrder = Object.entries(compsByCategory).sort(
    ([catA], [catB]) => catA - catB,
  );

  return (
    <>
      {compsByCategoryInOrder.map(([categoryId, comps]) => (
        <Box paddingBlockEnd={4}>
          <Heading size="sm">{categories[categoryId].title}</Heading>
          <Select placeholder="Select a competition" onChange={(e) => onChangeSelectedComp(categoryId, e.target.value)}>
            {comps.map((comp) => (
              <option
                value={comp.id}
                selected={selectedCompsByCategory[categoryId] === comp.id}
              >
                {comp.title}
              </option>
            ))}
          </Select>
        </Box>
      ))}
    </>
  );
};

export const CompetitionsModal = ({
  isOpen,
  onClose,
  eventId,
  userId,
  eventUserId,
}) => {
  const toast = useToast();
  const [selectedCompsByCategory, setSelectedCompsByCategory] = useState({});
  const { data, isLoading } = useQuery(FETCH_ELIGIBLE_EVENT_COMPETITIONS, {
    variables: {
      eventId,
      userId,
      eventUserId,
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const selectedComps = res.fetchEventsUserDetail.fetchMembers.reduce((selected, member) => ({
        ...selected,
        [member.fetchEventCompetition.fetchCompetition.categoryId]: member.fetchEventCompetition.id,
      }), {});
      setSelectedCompsByCategory(selectedComps);
    },
  });

  const onChangeSelectedComp = (categoryId, value) => {
    const compId = value === '' ? undefined : value;
    setSelectedCompsByCategory({
      ...selectedCompsByCategory,
      [categoryId]: compId,
    });
  };

  const [setEventCompetitions, { loading }] = useMutation(SET_EVENT_COMPETITIONS);
  const onConfirm = () => {
    setEventCompetitions({
      variables: {
        input: {
          eventsUserId: eventUserId,
          eventCompetitionIds: Object.values(selectedCompsByCategory).filter((compId) => !!compId),
        },
      },
    }).then(() => {
      toast({
        title: 'Competitions saved successfully',
        status: 'success',
        isClosable: true,
        position: 'top',
      });
      onClose();
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Competitions</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {(isLoading || !data) ? (
            <Spinner />
          ) : (
            <CompetitionsModalContent
              eventCompetitions={data.fetchEligibleEventCompetitions}
              selectedCompsByCategory={selectedCompsByCategory}
              onChangeSelectedComp={onChangeSelectedComp}
            />
          )}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onConfirm} isLoading={loading}>
            Save
          </Button>
          <Button colorScheme="gray" variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
