import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import { useLazyQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';

import { FETCH_EVENT_SCHOOL_TEAMS } from '../../GraphQL/Queries';
import Form, { PermissionButton } from '../../Components/Form';
import DeleteItem from '../../Components/DeleteItem/Index';
import SearchDialog from '../../Components/SearchDialog/Index';
import SEARCH from '../../Images/search.png';
import CROSS from '../../Images/cross.png';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';

const CoalitionForm = ({
  onSubmit, submitting, currentUser, formType, updateSchools, eventCompetitionId, alreadySelectedSchoolIds,
}) => {
  const { id } = useParams();
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [totalSchools, setTotalSchools] = useState([]);
  const [searchStringLength, setSearchStringLength] = useState(0);
  const [schoolIds, setSchoolIds] = useState([]);
  const [fetchSchools, { loading }] = useLazyQuery(
    FETCH_EVENT_SCHOOL_TEAMS, {
      onCompleted: (data) => {
        setFilteredSchools(data.eventSchoolTeams.map((team) => team.school).filter((school) => !alreadySelectedSchoolIds.includes(school.id)));
        setTotalSchools(data.eventSchoolTeams.map((team) => team.school));
      },
    },
  );
  const checkPermission = useUserPermissionChecker(currentUser);

  useEffect(() => {
    if (currentUser) {
      fetchSchools({
        variables: {
          eventId: id,
        },
      });
    }
  }, [currentUser, id, fetchSchools]);

  const searchSchools = (string) => {
    setFilteredSchools(totalSchools.filter((school) => school.name.toLowerCase().includes(string)
      && !alreadySelectedSchoolIds.includes(school.id)));
    setSearchStringLength(string.length);
  };

  const getSelectedSchool = (e) => {
    if (e.target.id !== '' && schoolIds.includes(parseInt(e.target.id, 10)) === false) {
      setSchoolIds(schoolIds.concat(parseInt(e.target.id, 10)));
      updateSchools(parseInt(e.target.id, 10), 'add');
      setSearchStringLength(0);
    }
  };

  const removeSchool = (id) => {
    setSchoolIds(schoolIds.filter((Id) => Id !== id));
    updateSchools(id, 'remove');
  };

  const wrappedOnSubmit = (e) => {
    if (formType === 'edit') {
      searchSchools('');
    }
    onSubmit(e);
  };

  return (
    <Form onSubmit={wrappedOnSubmit} className="pt-3">
      <SearchDialog
        placeholder="Search"
        onChange={(e) => searchSchools(e.target.value)}
        data={filteredSchools}
        stringlength={searchStringLength}
        onClick={(e) => getSelectedSchool(e)}
        displayattribute="name"
        imageattatched={SEARCH}
      />
      <div className="pt-3" />
      {schoolIds.map((schoolId) => (
        <DeleteItem
          key={schoolId}
          title={get('name', totalSchools[totalSchools.findIndex((x) => parseInt(x.id, 10) === schoolId)])}
          removeicon={CROSS}
          onClick={() => removeSchool(schoolId)}
        />
      ))}
      {formType === 'new'
        ? (
          <>
            {eventCompetitionId
              ? <PermissionButton loading={loading || submitting} backgroundcolor="#F4AB37" fontColor="#FFF" border="none" htmltype="submit" checkPermission={(checkPermission('View Event'))}>SUBMIT</PermissionButton>
              : null}
          </>
        )
        : <PermissionButton loading={loading || submitting} backgroundcolor="#F4AB37" fontColor="#FFF" border="none" htmltype="submit" checkPermission={(checkPermission('View Event'))}>SUBMIT</PermissionButton>}
      <div className="pt-3" />
    </Form>
  );
};

CoalitionForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  onSubmit: PropTypes.func.isRequired,
  formType: PropTypes.string.isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  updateSchools: PropTypes.func.isRequired,
  eventCompetitionId: PropTypes.number.isRequired,
  alreadySelectedSchoolIds: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
  }),
};
CoalitionForm.defaultProps = {
  alreadySelectedSchoolIds: [],
};

export default CoalitionForm;
